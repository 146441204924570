import styled from 'styled-components';
import { breakpoint, color } from 'styles/globals';
import Heading from 'styles/typography/Heading';

export const Wrapper = styled.div<{ $isMobileVisible: boolean }>`
  width: 100%;
  position: relative;
  padding:0 16px;

  hr {
    border-top: 1px solid ${color.secondaryLightGray};
    margin-top: 16px;
    margin-bottom: 16px
  }

  @media (${breakpoint.mdMin}) {
    padding: 0;
  }

  @media (${breakpoint.lgMin}) {
    border-left: 4px solid ${color.lightBlue};
    padding-right: 0;
    padding-left: 24px;
  }

  @media (-webkit-device-pixel-ratio: 2.125) and (${breakpoint.mdMin}) and (${breakpoint.lgMax}) {
    border-left: 4px solid ${color.lightBlue};
    padding-right: 0;
    padding-left: 24px;
  }

  @media (${breakpoint.hybridMax}) {
    ${({ $isMobileVisible }) => !$isMobileVisible && 'display: none;'}
  }

  @media (${breakpoint.hybridMin}) {
    &.no-top-margin {
      margin-top: 0;
    }
  }
`;

export const Title = styled(Heading).attrs((props) => ({
  className: props.className,
}))`
  border-bottom: ${color.darkGray} 1px solid;
  padding-bottom: 16px;
`;

export const List = styled.ul`
  display: block;
  width: 100%;
`;
