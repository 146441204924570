import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';

export const Wrapper = styled.li`
  list-style: none;
`;

export const Card = styled.div`
  padding-top: 16px;

  @media (${breakpoint.lgMin}) {
    padding-top: 12px;
  }

  @media (-webkit-device-pixel-ratio: 2.125) and (${breakpoint.mdMin}) and (${breakpoint.lgMax}) {
    padding-top: 12px;
  }

  .title-link {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 20px;
    max-width: 348px;
    text-align: left;
    text-decoration: none;
    transition: opacity 0.25s ease-in-out;

    @media (${breakpoint.xlMin}) {
      font-size: 20px;
      line-height: 22px;
    }
  }
`;

export const TimeAgo = styled.span<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'small' }, 'light')};
  color: ${getColor('textSecondary')};
  letter-spacing: 1.17px;
`;
